import React, { useContext, useEffect, useState, useRef, ReactElement } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { cloneDeep, get, has } from 'lodash-es';
import { toast } from 'react-toastify';
import { Icon, MenuItem, Input, FormLabel, FormControlLabel, RadioGroup, Radio, FormControl } from '@material-ui/core';

import AlertToast from 'components/AlertToast';
import CustomInput from 'components/CustomInput';
import CustomSelect from 'components/CustomSelect';
import Permission from 'components/Permission';
import UserDropdown from 'components/UserDropdown';
import { preventEnterSubmit } from 'components/utilities/preventEnterSubmit';
import { getResourceStatusIcon } from 'components/utilities/getStatusIcon';
import StatusIcon from 'components/StatusIcon';
import CustomNumberInput from 'components/CustomNumberInput';
import ResourceContext from 'pages/plans/ResourceContext';
import { getDurationMinusWeekends } from 'components/utilities/DateUtils';

import { Store } from 'store/reducers';
import { IUser } from 'typings/User';
import { CreateResource, UpdateResource, GetResourceStatusesResourceStatuses } from 'typings/_graphql';
import { qResources, qResourceTypes, qUsers, qResourceStatuses } from 'api/queries';

import Dates from './taskform/Dates';
import DeleteModal from './form/DeleteModal';
import FormButtons from './form/FormButtons';

import './resourceForm/ResourceForm.scss';
import './resourceForm/contractInformation/ContractInformation.scss';

interface ResourceFormProps {
    planId: number;
    readOnly?: boolean;
}

export interface ContractProps {
    id?: number;
    isActive?: boolean;
    title?: string;
    contractNumber?: string;
    TpocFirstName?: string;
    TpocLastName?: string;
}

const ResourceForm = ({ planId, readOnly: outerReadOnly = false }: ResourceFormProps): React.ReactElement => {
    const {
        control,
        register,
        handleSubmit,
        errors,
        reset,
        watch,
        setValue,
        getValues,
        formState: { isDirty: valuesHaveChanged },
    } = useForm({ mode: 'onSubmit' });

    const formHooks = { watch, setValue, getValues };
    const { offCanvasResource, setOffCanvasResource, resourceTypes, fundTypes } = useContext(ResourceContext);

    const userData: IUser = useSelector((storeState: Store) => storeState?.User);
    const [title, setTitle] = useState('Add a Resource');
    const [readOnly, setReadOnly] = useState(true);
    const [displayGovSpecificFields, setDisplayGovSpecificFields] = useState<boolean>(false);
    const [isActive, setIsActive] = useState(false);
    const [isInKind, setIsInKind] = useState(false);
    const [isCanvasClosed, setIsCanvasClosed] = useState(false);
    const modalRef = useRef();

    const defaultFormData = {
        resourceType: offCanvasResource.resourceTypeId || 0,
        name: '',
        description: '',
        fundType: 0,
        user: offCanvasResource.userId || userData.id,
        status: 0,
        amount: null,
        fundTypeId: null,
        startDate: null,
        dueDate: null,
        isDateRange: true,
        duration: 0,
        contracts_id: '',
        contracts_title: '',
        contracts_isActive: false,
        contracts_contractNumber: null,
        contracts_tpocFirstName: null,
        contracts_tpocLastName: null,
    };

    const [getResource, { data: resourceData, loading: getResourceLoading }] = useLazyQuery(
        qResources.QUERY_RESOURCE_BY_ID
    );

    const { data: users } = useQuery(qUsers.GET_USERS_BY_PLAN, {
        variables: { planId },
    });

    const { data: statusOptions } = useQuery(qResourceStatuses.QUERY_ALL);

    const [createResource] = useMutation<CreateResource>(qResources.CREATE_RESOURCE);
    const [updateResource] = useMutation<UpdateResource>(qResources.UPDATE_RESOURCE);

    const changeToEdit = () => {
        if (!outerReadOnly) {
            setReadOnly(false);
        }
    };

    const defaultEditResource = () => {
        setIsActive(false);
        if (offCanvasResource.editResource !== false && resourceData?.resource) {
            const { resource } = resourceData;
            const formResourceData = cloneDeep(defaultFormData);
            formResourceData.resourceType = offCanvasResource.resourceTypeId || 0;
            formResourceData.name = resource.name;
            formResourceData.description = resource.description;
            formResourceData.fundType = resource?.fundType?.id || 0;
            formResourceData.user = resource.user?.id;
            formResourceData.status = resource.status?.id;
            formResourceData.amount = resource.amount;
            formResourceData.startDate = resource.startDate;
            formResourceData.dueDate = resource.dueDate;
            formResourceData.duration = resource.duration;
            formResourceData.isDateRange = resource.isDateRange;

            if (resource.contracts && resource.contracts.length > 0) {
                const contract = resource.contracts[0];
                formResourceData.contracts_id = contract.id;
                formResourceData.contracts_title = contract.title;
                formResourceData.contracts_isActive = contract.isActive;
                formResourceData.contracts_contractNumber = contract.contractNumber;
                formResourceData.contracts_tpocFirstName = contract.TpocFirstName;
                formResourceData.contracts_tpocLastName = contract.TpocLastName;
                setIsActive(contract.isActive);
            }
            reset(formResourceData, { isDirty: false });
        } else {
            reset(defaultFormData);
        }
    };

    // Set some default form labels
    useEffect(() => {
        if (offCanvasResource.editResource !== false && has(offCanvasResource, 'editResource.id')) {
            setTitle(`Edit Resource`);
            getResource({ variables: { id: parseFloat(get(offCanvasResource, 'editResource.id') as string) } });
            setReadOnly(true);
        } else {
            setTitle('Add a Resource');
            reset(defaultFormData);
            setReadOnly(false);
        }
        if (outerReadOnly) {
            setTitle('');
            setReadOnly(true);
        }
    }, [offCanvasResource]);

    // Set default data when resource changes
    useEffect(() => {
        defaultEditResource();
    }, [resourceData, offCanvasResource, readOnly]);

    useEffect(() => {
        if (watch('resourceType') !== 1) {
            setDisplayGovSpecificFields(false);
        } else {
            setDisplayGovSpecificFields(true);
        }
    }, [watch('resourceType')]);

    // Calculate duration etc
    useEffect(() => {
        if (getValues('startDate') && getValues('dueDate')) {
            setValue('duration', getDurationMinusWeekends(getValues('startDate'), getValues('dueDate')));
        } else {
            setValue('duration', 0);
        }
    }, [watch('startDate'), watch('dueDate')]);

    useEffect(() => {
        setIsInKind(watch('resourceType') === 3 || false);
    }, [watch('resourceType')]);

    const onCancel = () => {
        setOffCanvasResource({ canvas: false, editResource: false, resourceTypeId: 0 });
        reset(defaultFormData);
        setIsActive(false);
    };

    if (getResourceLoading) {
        return (
            <div className="o-resourceForm">
                <h2>{title}</h2>
                <h4>Loading Resource...</h4>
            </div>
        );
    }

    const refetchQueries = [
        {
            query: qResourceTypes.QUERY_WITH_RESOURCES,
            variables: { planId },
        },
        {
            query: qUsers.GET_USERS_AND_RESOURCES,
            variables: { planId },
        },
        {
            query: qResources.QUERY_RESOURCES,
            variables: { planId },
        },
    ];

    const onSubmit = handleSubmit((data) => {
        const newData: any = {
            resourceTypeId: parseFloat(data.resourceType),
            name: data.name,
            description: data.description,
            userId: data.user,
            amount: data?.amount?.floatValue,
            startDate: data.startDate,
            dueDate: data.dueDate,
            isDateRange: data.isDateRange,
            duration: data.duration,
            statusId: data.status > 0 ? parseFloat(data.status) : null,
            fundTypeId: data.fundType > 0 ? data.fundType : null,
        };

        if (newData.amount === 0) newData.amount = null;

        if (isActive) {
            newData.contract = {};
            newData.contractId = parseInt(data.contracts_id, 10);
            newData.contract.title = data.contracts_title;
            newData.contract.isActive = isActive;
            newData.contract.contractNumber = data.contracts_contractNumber;
            newData.contract.TpocFirstName = data.contracts_tpocFirstName;
            newData.contract.TpocLastName = data.contracts_tpocLastName;
        }

        if (!offCanvasResource.editResource) {
            createResource({
                variables: { planId, data: newData },
                refetchQueries,
            })
                .then(() => {
                    toast(
                        <AlertToast severity="success" message={`Your Resource ${newData.label} has been created`} />
                    );
                    reset(defaultFormData);
                })
                .catch((e) => {
                    toast(<AlertToast severity="error" message={`Error creating Resource: ${e}`} />);
                });
        } else {
            updateResource({
                variables: {
                    planId,
                    resourceId: parseInt(offCanvasResource.editResource.id.toString(), 10),
                    data: newData,
                },
                refetchQueries,
            })
                .then(() => {
                    toast(<AlertToast severity="success" message={`Your Resource ${newData.name} has been Updated`} />);
                })
                .catch((e) => {
                    toast(<AlertToast severity="error" message={`Error updating Resource ${e}`} />);
                });
        }

        reset(defaultFormData);
        setOffCanvasResource({ canvas: isCanvasClosed, editResource: false });
    });

    const ShowMenu = (props: any): React.ReactElement =>
        offCanvasResource.editResource === false ? (
            <></>
        ) : (
            <div className={`o-resourceForm__resource-menu`}>{props.children}</div>
        );

    const RenderReadOnlyDisplay = ({
        readOnlyLabel,
        value,
        description = '',
    }: {
        readOnlyLabel: string;
        value: string | ReactElement;
        description?: string;
    }): ReactElement => (
        <div className="o-resourceForm__readOnlyContainer">
            <span className="o-resourceForm__readOnlyLabel">{readOnlyLabel}</span>
            {description && <p className="o-resourceForm__subLabel">{description}</p>}
            <p className="o-resourceForm__readOnlyContent">{value}</p>
        </div>
    );

    return (
        <div className={`o-resourceForm ${readOnly ? 'readonly' : ''}`}>
            <ShowMenu>
                <DeleteModal
                    objectToDelete={resourceData?.resource}
                    ref={modalRef}
                    afterClick={() => setOffCanvasResource({ canvas: false, editResource: false, resourceTypeId: 0 })}
                >
                    <Icon>delete</Icon>
                </DeleteModal>
            </ShowMenu>

            <Permission auth={`${readOnly} === false`} showFail={<h2>Resource Details</h2>}>
                <h2>{title}</h2>
            </Permission>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <form autoComplete="off" onKeyPress={preventEnterSubmit} onSubmit={onSubmit}>
                <div className="-hidden">
                    <Controller as={Input} name="startDate" control={control} />
                    <Controller as={Input} name="dueDate" control={control} />
                    <Controller as={Input} name="duration" control={control} />
                    <Controller as={Input} type="number" name="contracts_id" control={control} />
                    <input ref={register} name="isDateRange" type="checkbox" />
                    <input ref={register} name="contracts_isActive" type="checkbox" />
                </div>
                <Permission
                    auth={`${readOnly} === false`}
                    showFail={
                        <RenderReadOnlyDisplay
                            readOnlyLabel="Type"
                            value={resourceData?.resource?.resourceType?.name}
                        />
                    }
                >
                    <CustomSelect errors={errors} required inputLabel="Type" name="resourceType" control={control}>
                        <MenuItem disabled value={0}>
                            Select a Resource
                        </MenuItem>
                        {resourceTypes.map((resourceType) => (
                            <MenuItem key={resourceType.id} value={resourceType.id}>
                                <div className="o-resourceForm__resourceOption">{resourceType.name}</div>
                            </MenuItem>
                        ))}
                    </CustomSelect>
                </Permission>
                <hr className="o-resourceForm__hr" />
                <Permission
                    auth={`${readOnly} === false`}
                    showFail={<RenderReadOnlyDisplay readOnlyLabel="Name" value={resourceData?.resource?.name} />}
                >
                    <CustomInput
                        inputLabel="Name"
                        placeholder="Please Enter"
                        defaultValue={resourceData?.resource?.name}
                        errors={errors}
                        maxLength={30}
                        showCharacterCount
                        name="name"
                        inputRef={register()}
                        fullWidth
                        required
                    />
                </Permission>
                <Permission
                    auth={`${readOnly} === false`}
                    showFail={
                        <RenderReadOnlyDisplay
                            readOnlyLabel="Resource Description"
                            value={resourceData?.resource?.description || <em>No Description</em>}
                        />
                    }
                >
                    <CustomInput
                        inputLabel="Resource Description"
                        placeholder="Please Enter"
                        errors={errors}
                        name="description"
                        inputRef={register()}
                        defaultValue={resourceData?.resource?.description}
                        fullWidth
                        multiline
                    />
                </Permission>

                {displayGovSpecificFields && (
                    <Permission
                        auth={`${readOnly} === false`}
                        showFail={
                            <RenderReadOnlyDisplay
                                readOnlyLabel="Gov't Authorized Category"
                                value={resourceData?.resource?.fundType?.name}
                            />
                        }
                    >
                        <CustomSelect
                            inputLabel="Gov't Authorized Category"
                            name="fundType"
                            errors={errors}
                            required
                            control={control}
                            defaultValue={watch('fundType')}
                        >
                            <MenuItem disabled value={0}>
                                Select a Category
                            </MenuItem>
                            {fundTypes.map((fundTypeItem) => (
                                <MenuItem key={fundTypeItem.id} value={fundTypeItem.id}>
                                    {fundTypeItem.name}
                                </MenuItem>
                            ))}
                        </CustomSelect>
                    </Permission>
                )}

                <UserDropdown
                    control={control}
                    defaultValue={offCanvasResource.userId || userData.id}
                    name="user"
                    label="Assignee"
                    users={users?.usersByPlan || []}
                    readOnly={readOnly}
                />
                <Permission
                    auth={`${readOnly} === false`}
                    showFail={
                        <RenderReadOnlyDisplay
                            readOnlyLabel="Status"
                            value={
                                resourceData?.resource?.status?.status ? (
                                    <>
                                        <StatusIcon>
                                            {getResourceStatusIcon(resourceData?.resource?.status?.status)}
                                        </StatusIcon>
                                        <span>&nbsp;&nbsp;{resourceData?.resource?.status?.status}</span>
                                    </>
                                ) : (
                                    <em>No Status</em>
                                )
                            }
                        />
                    }
                >
                    <CustomSelect inputLabel="Status" name="status" control={control} defaultValue={watch('status')}>
                        <MenuItem disabled value={0}>
                            Select a Status
                        </MenuItem>
                        {statusOptions?.resourceStatuses?.map((statusOption: GetResourceStatusesResourceStatuses) => (
                            <MenuItem key={statusOption.id} value={statusOption.id}>
                                <div className="o-resourceForm__statusContainer">
                                    <StatusIcon>{getResourceStatusIcon(statusOption.status)}</StatusIcon>
                                    <span>&nbsp;&nbsp;{statusOption.status}</span>
                                </div>
                            </MenuItem>
                        ))}
                    </CustomSelect>
                </Permission>
                <Permission
                    auth={`${readOnly} === false`}
                    showFail={
                        <RenderReadOnlyDisplay
                            readOnlyLabel="Amount"
                            description={isInKind ? 'Monetary value associated with this In Kind resource.' : ''}
                            value={
                                resourceData?.resource?.amount === null ? (
                                    <em>No amount entered</em>
                                ) : (
                                    `$${resourceData?.resource?.amount.toLocaleString()}`
                                )
                            }
                        />
                    }
                >
                    <CustomNumberInput
                        inputLabel="Amount"
                        control={control}
                        helperText={
                            isInKind
                                ? 'Enter amount if there is a monetary value associated with this In Kind Resource'
                                : ''
                        }
                        placeholder="Amount"
                        errors={errors}
                        name="amount"
                        inputRef={register}
                        fullWidth
                    />
                </Permission>

                {displayGovSpecificFields && (
                    <>
                        <div className="o-contractInformation">
                            <h4 className="o-resourceForm__sectionTitle">Contract Information</h4>
                            {!isActive && readOnly && <p>No Active contracts</p>}
                            {!readOnly && (
                                <div className="o-contractInformation__section">
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Is this an active contract?</FormLabel>
                                        <RadioGroup
                                            value={isActive ? 'true' : ''}
                                            onChange={(e) => {
                                                setIsActive(!!e.target.value);
                                            }}
                                        >
                                            <FormControlLabel control={<Radio />} value="true" label="Yes" />
                                            <FormControlLabel control={<Radio />} value="" label="No" />
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                            )}

                            <div className={isActive ? '' : '-hidden'}>
                                <Permission
                                    auth={`${readOnly} === false`}
                                    showFail={
                                        <RenderReadOnlyDisplay
                                            readOnlyLabel="Contract Title"
                                            value={watch('contracts_title')}
                                        />
                                    }
                                >
                                    <div className="o-contractInformation__section">
                                        <CustomInput
                                            inputLabel="Contract Title"
                                            placeholder="Please Enter"
                                            errors={errors}
                                            name="contracts_title"
                                            inputRef={register}
                                            fullWidth
                                        />
                                    </div>
                                </Permission>
                                <Permission
                                    auth={`${readOnly} === false`}
                                    showFail={
                                        <RenderReadOnlyDisplay
                                            readOnlyLabel="Contract Number"
                                            value={watch('contracts_contractNumber')}
                                        />
                                    }
                                >
                                    <div className="o-contractInformation__section">
                                        <CustomInput
                                            inputLabel="Contract Number"
                                            placeholder="Please Enter"
                                            errors={errors}
                                            name="contracts_contractNumber"
                                            inputRef={register()}
                                            fullWidth
                                        />
                                    </div>
                                </Permission>
                                <Permission
                                    auth={`${readOnly} === false`}
                                    showFail={
                                        <RenderReadOnlyDisplay
                                            readOnlyLabel="TPOC First Name"
                                            value={watch('contracts_tpocFirstName')}
                                        />
                                    }
                                >
                                    <div className="o-contractInformation__section">
                                        <CustomInput
                                            inputLabel="TPOC First Name"
                                            placeholder="Enter Text"
                                            errors={errors}
                                            name="contracts_tpocFirstName"
                                            inputRef={register()}
                                            fullWidth
                                        />
                                    </div>
                                </Permission>
                                <Permission
                                    auth={`${readOnly} === false`}
                                    showFail={
                                        <RenderReadOnlyDisplay
                                            readOnlyLabel="TPOC Last Name"
                                            value={watch('contracts_tpocLastName')}
                                        />
                                    }
                                >
                                    <div className="o-contractInformation__section">
                                        <CustomInput
                                            inputLabel="TPOC Last Name"
                                            placeholder="Enter Text"
                                            errors={errors}
                                            name="contracts_tpocLastName"
                                            inputRef={register()}
                                            fullWidth
                                        />
                                    </div>
                                </Permission>
                            </div>
                        </div>
                    </>
                )}

                <h4 className="o-resourceForm__sectionTitle">Date</h4>
                <div className="o-resourceForm__dateContainer">
                    <Dates
                        formHooks={formHooks}
                        hideDuration
                        readOnly={readOnly}
                        startLabel={displayGovSpecificFields ? 'Contract Start Date' : 'Start Date'}
                        endLabel={displayGovSpecificFields ? 'Contract End Date' : 'End Date'}
                    />
                </div>

                <FormButtons
                    readOnly={readOnly}
                    isDisabled={
                        watch('resourceType') < 1 ||
                        watch('name') === '' ||
                        (watch('resourceType') === 1 && watch('fundType') === 0)
                    }
                    isNotEditMode={offCanvasResource.editResource === false}
                    areValuesChanged={valuesHaveChanged}
                    onEditHandler={changeToEdit}
                    onCancelHandler={onCancel}
                    setIsCanvasClosed={setIsCanvasClosed}
                />
            </form>
        </div>
    );
};

export default ResourceForm;
